import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyDAiMCohN61n9r-_SYRZCSuqQnU3gMVkIQ",
    authDomain: "portfolio-4d8be.firebaseapp.com",
    databaseURL: "https://portfolio-4d8be.firebaseio.com",
    projectId: "portfolio-4d8be",
    storageBucket: "portfolio-4d8be.appspot.com",
    messagingSenderId: "194942278060",
    appId: "1:194942278060:web:60def608449192c9c24209"
  };

  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.firestore().settings({});

  const storage = firebase.storage();

  export {storage, firebase as default};